
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import PackagesCreating from '@/views/manage/hub-shipments/packages/PackagesCreating.vue';
  import PackagesListing from '@/views/manage/hub-shipments/packages/PackagesListing.vue';
  import PackagesEditing from '@/views/manage/hub-shipments/packages/PackagesEditing.vue';
  import { Shipment } from '@/store/modules/Shipments/ShipmentsModule';
  import lgZoom from 'lightgallery/plugins/zoom';
  import lgVideo from 'lightgallery/plugins/video';

  export default defineComponent({
    name: 'hub-shipments-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
      PackagesCreating,
      PackagesListing,
      PackagesEditing,
    },
    data: () => ({
      plugins: [lgZoom, lgVideo],
    }),
    async setup() {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const loading = ref(false);
      const editData = ref<number>(0);
      const selectedOffice = ref<string>('');
      const videoInput = ref('');

      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
      }, 1500);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('shipmentUpdating'), [
          translate('shipments'),
        ]);
        reinitializeComponents();
      });

      // watch(
      //   () => selectedOrganizer.value,
      //   async () => {
      //     selectedLocation.value = 0;
      //     await store.dispatch(
      //       Actions.GET_ORGANIZER_LOCATIONS,
      //       selectedOrganizer.value
      //     );
      //   }
      // );

      const shipmentsSchema = Yup.object().shape({
        driverId: Yup.string()
          .notOneOf([''], () => translate('DRIVER_IS_REQUIRED_FIELD'))
          .required(() => translate('DRIVER_IS_REQUIRED_FIELD'))
          .label('Driver'),
      });

      const { data } = await store.dispatch(
        Actions.GET_HUB_SHIPMENT,
        route.params.id
      );

      const shipment = ref<Shipment>(data.data);

      const onSubmitUpdate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.UPDATE_HUB_SHIPMENT, {
          data: values,
          id: shipment.value.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_SHIPMENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            location.reload();
            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          });
        }
      };

      const refreshPackages = async () => {
        await store.dispatch(
          Actions.GET_HUB_SHIPMENT_PROPERTIES,
          shipment.value.id
        );
      };

      await refreshPackages();
      const showPackagesEditModal = (val) => {
        editData.value = val;
      };

      await Promise.all([
        await store.dispatch(Actions.GET_ALL_SHIPMENT_STATUSES, 'hub'),
      ]);

      const Driver = await store.dispatch(Actions.GET_ALL_DRIVERS);
      const drivers = Driver.data.data?.filter(
        (driver) =>
          driver.deletedAt == null || driver.id == shipment.value.driverId
      );

      return {
        shipment,
        drivers,
        editData,
        onSubmitUpdate,
        submitButton,
        translate,
        shipmentsSchema,
        goBack,
        can,
        loading,
        selectedOffice,
        videoInput,
        refreshPackages,
        showPackagesEditModal,
        shipmentStatuses: computed(() => store.getters.shipmentStatusesList),
      };
    },
  });
