
  import { computed, defineComponent, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { hideModal } from '@/core/helpers/dom';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n/index';

  export default defineComponent({
    name: 'hub-packages-creating',
    props: {
      shipmentId: { type: String, required: true },
    },
    emit: ['list-changed'],
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup(props, { emit }) {
      const submitButtonRef = ref<null | HTMLButtonElement>(null);
      const newPackagesModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const { t, te } = useI18n();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const validationSchema = Yup.object().shape({
        holderName: Yup.string()
          .required(() => translate('HOLDER_NAME_IS_REQUIRED_FIELD'))
          .label('Holder Name'),
        docId: Yup.string()
          .required(() => translate('DOC_ID_IS_REQUIRED_FIELD'))
          .label('docId'),
        recipientPhoneNumber: Yup.string()
          .matches(/^(77|78|75)\d{8}$/, 'Mobile number is not valid')
          .required(() => translate('RECIPIENT_PHONE_NUMBER_IS_REQUIRED_FIELD'))
          .label('Mobile'),

        address: Yup.string()
          .required(() => translate('ADDRESS_IS_REQUIRED_FIELD'))
          .label('address'),
      });

      const submit = async (values) => {
        if (!submitButtonRef.value) {
          return;
        }

        //Disable button
        submitButtonRef.value.disabled = true;
        // Activate indicator
        submitButtonRef.value.setAttribute('data-kt-indicator', 'on');
        values.shipmentId = props.shipmentId;
        values.packageType = 'supplier';

        values.recipientPhoneNumber = String(
          '964' + values.recipientPhoneNumber
        );
        const data = await store.dispatch(Actions.CREATE_SHIPMENT_PACKAGE, {
          data: values,
        });

        if (data?.id) {
          // await store.dispatch(Actions.UPLOAD_SHIPMENT_ATTACHMENTS, {
          //   data: attachmentsFormData,
          //   auctionId: data.id,
          // });
        }

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButtonRef.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_PACKAGE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            emit('list-changed');
            hideModal(newPackagesModalRef.value);
            //Deactivate indicator
            submitButtonRef.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButtonRef.value!.disabled = false;
          });
        }
      };

      return {
        translate,
        validationSchema,
        submit,
        submitButtonRef,
        newPackagesModalRef,
        propertyTypes: computed(() => store.getters.allAuctionPackagesTypes),
        statuses: computed(() => store.getters.auctionStatusesList),
        areas: computed(() => store.getters.auctionAreasList),
      };
    },
  });
