import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card mb-5 mb-xl-10"
}
const _hoisted_2 = {
  class: "card-header border-0",
  role: "button",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#kt_shipment_create_packages",
  "aria-expanded": "true",
  "aria-controls": "kt_shipment_create_packages"
}
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = {
  id: "kt_shipment_create_packages",
  class: "collapse show"
}
const _hoisted_6 = { class: "card-body border-top p-9" }
const _hoisted_7 = {
  key: 0,
  class: "notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6"
}
const _hoisted_8 = { class: "svg-icon svg-icon-2tx svg-icon-primary me-4" }
const _hoisted_9 = { class: "d-flex flex-stack flex-grow-1" }
const _hoisted_10 = { class: "fw-bold" }
const _hoisted_11 = { class: "fs-6 text-gray-600" }
const _hoisted_12 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_13 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_14 = { class: "badge-light-info badge" }
const _hoisted_15 = { class: "badge-light-info badge" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "svg-icon svg-icon-3" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "svg-icon svg-icon-3" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "svg-icon svg-icon-3" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('create', 'hubs-shipments'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.translate('shipmentPackages')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (!_ctx.createdShipment)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen045.svg" })
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.translate('savingShipmentPackagesNote')), 1)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_Datatable, {
              "table-header": _ctx.tableHeader,
              "table-data": _ctx.tableData,
              "rows-per-page": 50,
              total: _ctx.totalItems,
              onCurrentChange: _ctx.pageChanged,
              loading: _ctx.loading,
              "enable-items-per-page-dropdown": false
            }, {
              "cell-isPrinted": _withCtx(({ row: packages }) => [
                (packages.isPrinted)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.translate('Yes')), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.translate('No')), 1))
              ]),
              "cell-trackNumber": _withCtx(({ row: packages }) => [
                _createTextVNode(_toDisplayString(packages.trackNumber), 1)
              ]),
              "cell-holderName": _withCtx(({ row: property }) => [
                _createTextVNode(_toDisplayString(property.holderName), 1)
              ]),
              "cell-areaName": _withCtx(({ row: property }) => [
                _createTextVNode(_toDisplayString(property.areaName), 1)
              ]),
              "cell-recipientPhoneNumber": _withCtx(({ row: property }) => [
                _createTextVNode(_toDisplayString(property.recipientPhoneNumber), 1)
              ]),
              "cell-docId": _withCtx(({ row: property }) => [
                _createTextVNode(_toDisplayString(property.docId), 1)
              ]),
              "cell-address": _withCtx(({ row: property }) => [
                _createTextVNode(_toDisplayString(property.address), 1)
              ]),
              "cell-notes": _withCtx(({ row: property }) => [
                _createTextVNode(_toDisplayString(property.notes), 1)
              ]),
              "cell-internalStatus": _withCtx(({ row: packages }) => [
                _createElementVNode("span", _hoisted_14, _toDisplayString(packages.internalStatus), 1)
              ]),
              "cell-externalStatus": _withCtx(({ row: packages }) => [
                _createElementVNode("span", _hoisted_15, _toDisplayString(packages.externalStatus), 1)
              ]),
              "cell-actions": _withCtx(({ row: property }) => [
                _createElementVNode("a", {
                  href: "#",
                  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2",
                  onClick: _withModifiers(($event: any) => (_ctx.overView(property.id)), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_17, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen021.svg" })
                  ])
                ], 8, _hoisted_16),
                _createElementVNode("a", {
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#kt_modal_edit_packages",
                  href: "#",
                  class: _normalizeClass([_ctx.disClass(_ctx.createdShipment.isEditable), "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"]),
                  onClick: _withModifiers(($event: any) => (_ctx.editOption(property.id)), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_19, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/art/art005.svg" })
                  ])
                ], 10, _hoisted_18),
                _createElementVNode("a", {
                  href: "#",
                  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2",
                  onClick: _withModifiers(($event: any) => (_ctx.printBtn(property)), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_21, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/files/fil025.svg" })
                  ])
                ], 8, _hoisted_20),
                _createElementVNode("a", {
                  href: "#",
                  class: _normalizeClass(_ctx.disClass(_ctx.createdShipment.isEditable)),
                  onClick: _withModifiers(($event: any) => (_ctx.toggleStatusPackages(property.id)), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_23, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen027.svg" })
                  ])
                ], 10, _hoisted_22)
              ]),
              _: 1
            }, 8, ["table-header", "table-data", "total", "onCurrentChange", "loading"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}